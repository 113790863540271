$(document).ready(function (){

    $('.js-contact').on('click', function(e){

        e.preventDefault();

        //validation
        msg = "";
        valid = true;
        error_msg = "";

        //
        form = $(this).closest('form');

        //check required fields
        form.checkRequiredFields();

        if (!valid) {
            error_msg += msg;
        }

        //
        if (valid) {

            post_data = form.serialize();

            url = "../php/ajax/form.php?action=send-enquiry";

            $.post(url, post_data, function (data) {

                try {
                    response = JSON.parse(data);

                    if (response.success) {

                        //Success
                        Apprise("<h2>Message sent</h2><p>Thank you, we'll be in touch soon.</p>");

                        form.find('input, textarea').val('');

                    }
                    else {
                        valid = false;
                        error_msg += response.error_message;

                        Apprise(error_msg);
                    }
                }
                catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    Apprise(error_msg);
                }

            });

        }
        else {
            error_msg = "<h1>Before you finish...</h1><ul>" + error_msg + "</ul>";
            Apprise(error_msg, default_apprise_options);
        }

    });

});
