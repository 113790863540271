$('.review-box__star-box > span.review-star').on('click', function () {

    if (!$('body').hasClass('signed-in')) {
        Apprise('<h2>Sign in required</h2><p>Please sign in before you review a product.</p>');
    }

});

$('.signed-in .review-box__star-box > span.review-star').on('click', function () {

    //Mark this and previous stars as active
    $(this).removeClass('empty-star').addClass('star');
    $(this).prevAll('.review-box__star-box > span').removeClass('empty-star').addClass('star');

    //Mark ones after as empty
    $(this).nextAll('.review-box__star-box > span').removeClass('star').addClass('empty-star');

    //Actually save review
    valid = true;
    error_msg = '';

    stars = $('.review-box__star-box > span.review-star.star').length;
    product_id = $('.review-box').attr('data-product_id');

    post_data = {
        stars: stars,
        product_id: product_id
    };

    url = "../php/ajax/review.php?action=save";

    $.post(url, post_data, function (data) {

        try {
            response = JSON.parse(data);

            if (response.success) {

            }
            else {
                valid = false;
                error_msg += response.error_message;
                Apprise(error_msg);
            }
        }
        catch (e) {
            valid = false;
            error_msg += "<li>Technical error: " + data + "</li>";
            Apprise(error_msg);
        }

    });
});

$('.js-review-reveal').on('click', function (e) {

    e.preventDefault();

    $('.review-box__comments').slideToggle();

});

$('.review-box__comments button').on('click', function (e) {

    e.preventDefault();

    //validation
    msg = "";
    valid = true;
    error_msg = "";

    //
    form = $(this).closest('.review-box__comments');

    //check required fields
    form.checkRequiredFields();

    if (!valid) {
        error_msg += msg;
    }

    //
    if (valid) {

        comment = $('.review-box__comments textarea').val();
        product_id = $('.review-box').attr('data-product_id');

        post_data = {
            comment: comment,
            product_id: product_id
        };

        url = "../php/ajax/review.php?action=save-comment";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    Apprise('<h2>Thank you</h2><p>We appreciate your review.</p>');
                    $('.review-box__comments').slideUp();
                    $('.js-review-reveal').hide();

                }
                else {
                    valid = false;
                    error_msg += response.error_message;
                    console.log(data);
                    Apprise(error_msg);
                }
            }
            catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                Apprise(error_msg);
            }

        });

    }
    else {
        error_msg = "<h1>Before you finish...</h1><ul>" + error_msg + "</ul>";
        Apprise(error_msg, default_apprise_options);
    }


});

$('.js-reveal-reviews').on('click', function (e) {

    $('.review-box__reviews').slideToggle();

});
