//
var default_apprise_options = {
    buttons: {

        checkout: {
            text: 'Okay, I\'ll fix it',
            className: 'main',
            action: function (e)
            {
                Apprise('close');
            }
        }
    }
};