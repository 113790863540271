/**
 * Created by benwa on 26/06/2017.
 */

$('.js-remove-product').click(function (event) {
    valid = true;
    error_msg = "";

    qty = $(this).val();
    row = $(this).closest('tr');
    product_id = row.attr('data-product_id');

    console.log("product_id", product_id);

    //Remove line from page
    row.remove();

    if (row.length) {
        row.remove();
    }

    //Remove from basket
    post_data = {
        product_id: product_id
    };

    url = "../php/ajax/basket.php?action=delete-product";

    $.post(url, post_data, function (data) {
        try {
            response = JSON.parse(data);

            if (response.success) {
                //
            } else {
                valid = false;
                error_msg += response.error_message;
                console.log(data);
                Apprise(error_msg);
            }
        } catch (e) {
            valid = false;
            error_msg += "<li>Technical error: " + data + "</li>";
            Apprise(error_msg);
        }

    });

    //Refresh the basket
    url = "../php/ajax/basket.php?action=refresh-basket";

    post_data = {
        product_id: product_id,
        qty: qty,
        country_id: $('.js-country').val()
    };

    $.post(url, post_data, function (data) {
        try {
            response = JSON.parse(data);

            if (response.success) {

                //Get just .cart-table
                cart = $($.parseHTML(response.checkout));
                cart_table = cart.find('.cart-table');

                //Replace .cart-table
                $('.cart-table').html(cart_table.html());

                //Update mini basket
                total_qty = parseInt(0);
                $('input[name=quantity]').each(function () {
                    total_qty += parseInt($(this).val());
                });

                $('.main-header__mini-cart span').html(total_qty);

            } else {
                valid = false;
                error_msg += response.error_message;
                console.log(data);
                Apprise(error_msg);
            }
        } catch (e) {
            valid = false;
            console.log(e);
            error_msg += "<li>Technical error: " + data + "</li>";
            Apprise(error_msg);
        }

    });
});

//Update basket on quantity change
$('.cart').on('change', '.cart-table input[name=quantity]', function () {

    valid = true;
    error_msg = "";

    qty = $(this).val();
    row = $(this).closest('tr');
    product_id = row.attr('data-product_id');

    if (qty < 1) {

        //Remove from basket
        post_data = {
            product_id: product_id
        };

        url = "../php/ajax/basket.php?action=delete-product";

        $.post(url, post_data, function (data) {
            try {
                response = JSON.parse(data);

                if (response.success) {
                    //Remove line from page
                    row.remove();

                    if (row.length) {
                        row.remove();
                    }
                } else {
                    valid = false;
                    error_msg += response.error_message;
                    console.log(data);
                    Apprise(error_msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                Apprise(error_msg);
            }

        });
    }

    //Refresh the basket
    url = "../php/ajax/basket.php?action=refresh-basket";

    post_data = {
        product_id: product_id,
        qty: qty,
        country_id: $('.js-country').val()
    };

    $.post(url, post_data, function (data) {
        try {
            response = JSON.parse(data);

            if (response.success) {

                //Get just .cart-table
                cart = $($.parseHTML(response.checkout));
                cart_table = cart.find('.cart-table');

                //Replace .cart-table
                $('.cart-table').html(cart_table.html());

                //Update mini basket
                total_qty = parseInt(0);
                $('input[name=quantity]').each(function () {
                    total_qty += parseInt($(this).val());
                });

                $('.main-header__mini-cart span').html(total_qty);

            } else {
                valid = false;
                error_msg += response.error_message;
                console.log(data);
                Apprise(error_msg);
            }
        } catch (e) {
            valid = false;
            console.log(e);
            error_msg += "<li>Technical error: " + data + "</li>";
            Apprise(error_msg);
        }

    });

});

//Update shipping on country update
$('.cart select[name=country_id]').on('change', function () {

    if ($(this).val()) {

        valid = true;
        error_msg = '';

        country_id = $(this).val();
        weight = $('.cart').attr('data-weight');
        subtotal = $('.cart').attr('data-subtotal');

        console.log(subtotal);

        post_data = {
            country_id: country_id,
            weight: weight,
            subtotal: subtotal
        };

        url = "../php/ajax/basket.php?action=get-shipping-price";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    //Work out total
                    subtotal = $('.cart-table__sub-total').text();
                    subtotal = Number(subtotal.replace(/[^0-9\.]+/g, ""));

                    shipping_price = response.price;
                    shipping_price = Number(shipping_price.replace(/[^0-9\.]+/g, ""));

                    total = subtotal + shipping_price;
                    total = total.toFixed(2);
                    total = "&pound;" + total;

                    $('.cart-table__delivery-text').html(response.price);
                    $('.cart-table__total-text').html(total);

                } else {
                    valid = false;
                    error_msg += response.error_message;
                    Apprise(error_msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                Apprise(error_msg);
            }

        });
    }

});

//Set publishable key
if ($('input[name=stripe_publishable_token]').val()) {
    Stripe.setPublishableKey($('input[name=stripe_publishable_token]').val());
}

//Checkout

$('.js-pay').on('click', function (e) {
    e.preventDefault();

    //validation
    msg = "";
    valid = true;
    error_msg = "";

    //
    form = $(this).closest('div');
    button = $(this);

    //check required fields
    form.checkRequiredFields();

    //
    if (!isValidEmailAddress($('input[name=email]').val())) {
        valid = false;
        error_msg += "<li>Please provide a valid email address</li>";
    }

    //check terms agreed
    if ($('input[name=agree_terms]').length > 0) {
        if (!$('input[name=agree_terms]').is(':checked')) {

            valid = false;

            error_msg += "You must agree to the terms to continue.";
        }
    }

    if (!valid) {
        error_msg += msg;
    }

    //
    if (button.hasClass('loading')) {
        valid = false;
        error_msg += "<li>Checkout already in progress</li>";
    }

    //
    if (valid) {
        button.addClass('loading').text('Please wait...');

        //Tell stripe to encode card details to secure token, then pass it to stripeResponseHandler function
        Stripe.card.createToken(form, stripeResponseHandler);
    } else {
        error_msg = "<h1>Before you finish...</h1><ul>" + error_msg + "</ul>";
        Apprise(error_msg, default_apprise_options);
    }

});

//Stripe processing triggered by checkout process
function stripeResponseHandler(status, response) {
    console.log('stripeResponseHandler()');

    form = $('.cart__form');
    button = $('.js-pay');

    if (response.error) {

        msg = "<h1>There was a problem with your card</h1>" +

            "<ul><li>" + response.error.message + "</li></ul>";

        Apprise(msg, default_apprise_options);

        button.removeClass('loading').text('Try again');

    } else {
        // token contains id, last4, and card type
        var token = response.id;

        // Insert the token into the form so it gets submitted to the server
        form.append($('<input type="hidden" name="stripeToken" />').val(token));

        //Continue with rest of order processing
        checkoutOrder();

    }

};

//Checkout logic triggered by StripeResponseHandler function above
function checkoutOrder() {
    console.log('checkoutOrder()');
    button = $('.js-pay');

    //take payment
    mailing_list = 0;
    if ($('input[name=mailing_list]').is(':checked')) {
        mailing_list = 1;
    }

    post_data = {
        name: $('input[name=name]').val(),
        address: $('input[name=address]').val(),
        town_city: $('input[name=town_city]').val(),
        country_id: $('select[name=country_id]').val(),
        postcode: $('input[name=postcode]').val(),
        delivery_note: $('input[name=delivery_note]').val(),
        email: $('input[name=email]').val(),
        phone: $('input[name=phone]').val(),
        mobile: $('input[name=mobile]').val(),
        password: $('input[name=password]').val(),
        mailing_list: mailing_list,
        stripeToken: $('input[name=stripeToken]').val()
    };

    url = "../php/ajax/basket.php?action=checkout";

    $.post(url, post_data, function (data) {
        console.log('data', data);
        try {
            response = JSON.parse(data);
            console.log('response', response);

            if (response.success) {

                confirmation = "<h1>Order placed! Thank you so much for your custom!</h1>";

                var options = {
                    override: false,
                    buttons: {
                        checkout: {
                            text: 'Finish',
                            className: 'main',
                            action: function (e) {
                                location.href = "/";
                            }
                        }
                    }
                };

                Apprise(confirmation, options);

            } else {
                valid = false;
                error_msg += response.error_message;
                console.log(data);
                Apprise(error_msg + ' #A');

                button.removeClass('loading').text('Try again');
            }
        } catch (e) {
            valid = false;
            error_msg += "<li>Technical error: " + data + "</li>";
            Apprise(error_msg + ' #B');
            console.log(e);

            button.removeClass('loading').text('Try again');
        }

    });
}

//Continue in cart
$('.js-cart-continue').on('click', function (e) {

    e.preventDefault();

    //validation
    msg = "";
    valid = true;
    error_msg = "";

    //
    form = $(this).closest('form');


    $('.js-product-cart').each(function () {
        product = $(this);

        console.log('product', product);

        quantity = product.find('.js-product-quantity').val();
        stock_level = product.attr('data_stock-level');
        title = product.attr('data-product_title');

        console.log(quantity, stock_level);

        if (quantity > stock_level) {
            console.log('aaaa');
            valid = false;
            error_msg = "You're trying to order " + quantity + " of  " + title + " but only " + stock_level + " are available, please correct this to continue.";
        }
    });

    if (valid) {
        //check required fields
        form.checkRequiredFields();
    }

    if (!valid) {
        error_msg += msg;
    }

    //
    if (valid) {
        $('.step-1').slideUp();
        $('.step-2').slideDown();
    } else {
        error_msg = "<h1>Before you finish...</h1><ul>" + error_msg + "</ul>";

        Apprise(error_msg, default_apprise_options);
    }
});

//Remove voucher
$('.delete_voucher').on('click', function (e) {

    e.preventDefault();

    msg = "";
    valid = true;
    error_msg = "";

    url = "../php/ajax/basket.php?action=delete-voucher";

    $.post(url, {}, function (data) {

        try {
            response = JSON.parse(data);

            console.log(response);

            if (response.success) {
                location.reload();
            } else {
                valid = false;
                error_msg += response.error_message;
                console.log(data);
                Apprise(error_msg);
            }
        } catch (e) {
            valid = false;
            error_msg += "<li>Technical error: " + data + "</li>";
            Apprise(error_msg);
        }

    });
});

//Apply voucher
$('.cart-table__voucher-input button').on('click', function (e) {

    e.preventDefault();

    var voucher_code = $('#voucher_code_input').val();

    msg = "";
    valid = true;
    error_msg = "";

    if (voucher_code != '') {
        post_data = {
            voucher_code: voucher_code,
        };

        url = "../php/ajax/basket.php?action=add-voucher";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {
                    location.reload();
                } else {
                    valid = false;
                    error_msg += response.error_message;
                    Apprise(error_msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                Apprise(error_msg);
            }

        });

    }

});

//Hide voucher input to start
$('.cart-table__voucher-input').slideUp();

//Show voucher input on link click
$('.cart-table__voucher-code').on('click', function (e) {
    e.preventDefault();

    $('.cart-table__voucher-input').slideDown();
});

//Clicking add to basket button
$('.js-add-to-basket').on('click', function (e) {

    e.preventDefault();

    quantity = $('input[name=qty]').val();
    product_id = $('input[name=product_id]').val();
    product_title = $('.product-detail__title').text();

    //validation
    msg = "";
    valid = true;
    error_msg = "";

    //
    form = $(this).closest('div');

    //check required fields
    form.checkRequiredFields();

    if (!valid) {
        error_msg += msg;
    }

    //
    if (valid) {
        post_data = {
            quantity: quantity,
            /*size_id: size_id,*/
            product_id: product_id
        };

        url = "../php/ajax/basket.php?action=add-product";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    var options = {
                        override: false,
                        buttons: {

                            checkout: {
                                text: 'Checkout now',
                                className: 'checkout main',
                                action: function (e) {
                                    location.href = "/checkout";
                                }
                            },

                            continue: {
                                text: 'Or continue shopping?',
                                className: 'continue small',
                                action: function (e) {
                                    Apprise('close');
                                }
                            }
                        }
                    };

                    confirmation = "<h1>You’ve added " + quantity + " &times; " + product_title + " to your basket. Thanks!</h1>";

                    Apprise(confirmation, options);

                    //Update mini basket
                    old_qty = parseInt($('.main-header__mini-cart span').html());
                    to_add = parseInt(quantity);
                    basket_count = old_qty + to_add;

                    $('.main-header__mini-cart span').text(basket_count);

                } else {
                    valid = false;
                    error_msg += response.error_message;
                    console.log(data);
                    Apprise(error_msg);
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                Apprise(error_msg);
            }

        });

    } else {
        error_msg = "<h1>Before you finish...</h1><ul>" + error_msg + "</ul>";
        Apprise(error_msg, default_apprise_options);
    }

});
