$(document).ready(function(){

	$('.banners').initialiseCycle();
	
	banner_w = 1280;
	banner_h = 512;
	
	$(window).on('load resize ready orientationchange', function(){
		
		window_w = $(window).width();
					
		new_banner_h = (window_w / banner_w) * banner_h;
				
		$('li.banner, .banners li, .banners li div, .banners li a, .banner-container').height(new_banner_h).width(window_w);
		
	});
	
});
	
$.fn.initialiseCycle = function(){
	
	slideshow = this;
	
	options = {	'slides': 		'> li',
				'swipe': 		true,
				'speed': 		2000,
				'easing': 		'easeOutCirc',				
				'manualSpeed': 	1000,
				'fx': 			'scrollHorz',
				'timeout':	 	8000,
				'next':   '.next', 
    			'prev':   '.prev' 
			  };
			  
	slideshow.cycle(options);

}