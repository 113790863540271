$(document).ready(function () {

    //Hide filters that have no options to show (or 1 option!)
    $('.js-filter').each(function () {
        if ($(this).find('label').length <= 1) {
            $(this).hide();
        }
    });

    //Clear filters
    $('.js-clear-filters').on('click', function (e) {

        e.preventDefault();

        var path = location.pathname;

        path = path.split('\/');

        location = '/' + path[1];

    });


    //Standard filter
    $('.js-filter input').on('change', function () {
        $('body').attr('data_filter-changed', 1);
        setFilters($('.js-pager').first());
    });

    //Price range slider
    if ($('.js-price-slider').length > 0) {

        min = parseInt($('.js-price-slider-wrapper .min').attr('data-value'));
        max = parseInt($('.js-price-slider-wrapper .max').attr('data-value'));

        $('.js-price-slider').noUiSlider({
            start: [min, max],

            step: 1,

            range: {
                'min': [min],
                'max': [max]
            }

        }).Link('upper').to('-inline-<div class="tooltip"></div>', function (value) {

            $('.max').html(
                '&pound;' + parseInt(value) + ''
            );
        }).Link('lower').to('-inline-<div class="tooltip"></div>', function (value) {

            $('.min').html(
                '&pound;' + parseInt(value) + ''
            );
        }).on('set', function () {
            setFilters($('.js-pager').first());
            $('body').attr('data_filter-changed', 1);
        });
    }

    $('.js-reset-filters').on('click', function (e) {

        e.preventDefault();

        $('.js-product').show();
        $('.group input[type=radio]').prop('checked', false);

        if ($('.js-price-slider').length > 0) {
            max = $('.js-price-slider-wrapper .max').attr('data-value');
            min = $('.js-price-slider-wrapper .min').attr('data-value');

            $('.js-price-slider').val([min, max]);
        }

        $('.js-reset-filters').hide();

    });

});

function setFilters(pager) {

    console.log('setFilters()');

    if ($('.js-pager').length > 0) {
        $('html, body').animate({
            scrollTop: $('.js-pager').first().offset().top
        }, 250);
    }

    var options = [];

    //Get list of checked filters
    $('.js-filter input').each(function () {

        var input = $(this);

        if (input.is(':checked')) {
            options.push(input.val());
        }

    });

    //Check price filters
    var range = $('.js-price-slider').val();
    range = range.toString();
    range = range.split(',');
    var min_price = range[0];
    var max_price = range[1];

    options = options.join(',');

    var category_id = $('.js-filter-results').attr('data-category_id');

    var page = pager.val();

    if (parseInt($('body').attr('data_filter-changed')) == 1) {
        page = 1;

        $('body').attr('data_filter-changed', 0);
    }

    post_data = {
        options: options,
        min_price: min_price,
        max_price: max_price,
        category_id: category_id,
        page: page
    };

    console.log(post_data);

    $('.js-filter-results').fadeOut();

    var url = "../php/ajax/filter.php?action=update-products";

    $.post(url, post_data, function (data) {

        try {
            var response = JSON.parse(data);
            $('.js-filter-results').html(response.html).fadeIn();
            adjustSameHeights();
        }
        catch (e) {
            valid = false;
            var error_msg = "<li>Technical error: " + data + "</li>";
            Apprise(error_msg);
        }

    });

}