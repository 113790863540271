$(document).ready(function(){

    //Check forms for required fields
    $.fn.checkRequiredFields = function(){

        form = this;

        msg = "";

        //check required fields
        form.find('.required').each(function(){

            placeholder = $(this).attr('placeholder');

            if( $(this).is('select') )
            {
                value = $(this).find(':selected').val();
            }
            else
            {
                value = $(this).val();
            }

            if(!value && ((($(this).is(':visible') || $(this).attr('type') == "hidden") && $(this).parent().is(':visible') ) || ($(this).hasClass('autocomplete') && $(this).parent().is(':visible'))) || (value == placeholder) )
            {
                if($(this).attr('name') == 'files_container')
                {
                    wrapper = $(this).closest('div.file');

                    if(wrapper.find('ul > li').length == 0)
                    {
                        msg += "<li>" + placeholder + " appears to be empty.</li>";
                    }
                }
                else
                {
                    msg += "<li>" + placeholder + " appears to be empty.</li>";
                }
            }

        });

        if(msg != "")
        {
            valid = false;
        }

        return msg;
    }


    //Capture return key from forms
    $('form').keypress(function(e) {

        if(e.which == 13)
        {

            if( !$(e.target).hasClass('wysiwyg') && !$(e.target).is('textarea') )
            {
                $(this).find('button').not('.file button, .files button, .mce-tinymce button').trigger('click');
                return false;
            }

        }

    });


});
