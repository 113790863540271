/**
 * Created by benwa on 27/06/2017.
 */
$('.signup__form').on('submit', function (e) {

    e.preventDefault();

    //validation
    msg = "";
    valid = true;
    error_msg = "";

    //
    form = $(this).closest('form');

    //check required fields
    form.checkRequiredFields();

    if (!valid) {
        error_msg += msg;
    }

    //check terms agreed
    if (!$('input[name=agree_terms]').is(':checked')) {

        valid = false;

        error_msg += "You must agree to the terms to continue.";
    }

    //
    if (valid) {

        post_data = form.serialize();

        url = "../php/ajax/customer.php?action=register";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    $('.register-form').hide();
                    $('.success-message').show();

                }
                else {
                    valid = false;
                    error_msg += response.error_message;
                    console.log(data);
                    Apprise(error_msg);
                }
            }
            catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                Apprise(error_msg);
            }

        });

    }
    else {
        error_msg = "<h1>Before you finish...</h1><ul>" + error_msg + "</ul>";
        Apprise(error_msg, default_apprise_options);
    }


});