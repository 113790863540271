//Account options list
$('.account__options li a').on('click', function(e){

    e.preventDefault();

    section = $(this).closest('li').attr('data-section');

    $('.account__column-2 section').hide();
    $('.account__column-2 section[data-name=' + section + ']').show();

    $('.account__options li').removeClass('active');
    $(this).closest('li').addClass('active');

});


//Update details
$('.js-save-account-details').on('click', function (e) {

    e.preventDefault();

    //validation
    msg = "";
    valid = true;
    error_msg = "";

    //
    form = $(this).closest('form');

    //check required fields
    form.checkRequiredFields();

    if (!valid) {
        error_msg += msg;
    }

    //
    if (valid) {

        post_data = form.serialize();

        url = "../php/ajax/customer.php?action=update";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    Apprise("<h2>Update successful</h2><p>Thank you, your details have been updated.</p>")

                }
                else {
                    valid = false;
                    error_msg += response.error_message;
                    console.log(data);
                    Apprise(error_msg);
                }
            }
            catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                Apprise(error_msg);
            }

        });

    }
    else {
        error_msg = "<h1>Before you finish...</h1><ul>" + error_msg + "</ul>";
        Apprise(error_msg, default_apprise_options);
    }


});

//Change password
//Update details
$('.js-update-password').on('click', function (e) {

    e.preventDefault();

    //validation
    msg = "";
    valid = true;
    error_msg = "";

    //
    form = $(this).closest('form');

    //check required fields
    form.checkRequiredFields();

    if (!valid) {
        error_msg += msg;
    }

    //
    if (valid) {

        post_data = form.serialize();

        url = "../php/ajax/customer.php?action=update-password";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    Apprise("<h2>Update successful</h2><p>Thank you, your password has been updated.</p>")

                }
                else {
                    valid = false;
                    error_msg += response.error_message;
                    console.log(data);
                    Apprise(error_msg);
                }
            }
            catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                Apprise(error_msg);
            }

        });

    }
    else {
        error_msg = "<h1>Before you finish...</h1><ul>" + error_msg + "</ul>";
        Apprise(error_msg, default_apprise_options);
    }


});