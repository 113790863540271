$(document).ready(function() {

    //Product zoomy
	var productImageBox = $(".js-product-detail-box")
	var productImage = $(".js-product-detail-image");
	
	
	productImageBox.on('mousemove', function(e){
	  productImage.css({'transform-origin': ((e.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' + ((e.pageY - $(this).offset().top) / $(this).height()) * 100 +'%'});
	});
	
	
	//Open mobile nav
	$('.js-mobile-nav').on('click', function(e){

		e.preventDefault();
		
		$('body').toggleClass('mobile-nav-open');
		
	});	
	
	//Open subnav on mobile	
	$('.mobile-nav__list > li').on('click', function(e){

		e.preventDefault();
				
		//Close any open subnav
		$('.mobile-nav__list > li > ul').slideUp();
		
		//Open up this subnav
		$(this).find('ul').slideDown();
		
	});
	
	//Make subnav clickable on mobile
	$('.mobile-nav__list > li li').on('click', function(e){

		e.preventDefault();
				
		href = $(this).find('a').attr('href');
		
		location.href = href;
		
		return false;
		
	});
	
	//Open search box on mobile
	$('.js-mobile-search').on('click', function(e){

		e.preventDefault();
		
		$('body').toggleClass('mobile-search-open');
		
	});	
	
	//Capture keypress in search field
	$('input[name=search]').keypress(function(e) {
		
	    if(e.which == 13)
	    {
			e.preventDefault();
	      	location.href = "search/" + encodeURIComponent($(this).val());		  
	    }
	    
	});	
	
	//Capture search button press
	$('.btn--search').click(function(e) {

		e.preventDefault();
		
		search_query = $(this).closest('div').find('input').val();
		
      	location.href = "search/" + encodeURIComponent(search_query);		  

	});			


});

//Verify emails
function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
};